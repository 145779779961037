import { ResetPasswordType, SnackbarStyle } from '@/constants/constants'
import i18n from '@/i18n'
import store from '@/store/store'
import Vue from 'vue'
import Router from 'vue-router'
import { privilegesService } from './business/privilegesService'

Vue.use(Router)

const defaultTitle = 'GESTYA Portal Agro'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/LoginView.vue')
    },
    {
      path: '/reset-password',
      name: 'emailResetPassword',
      component: () => import(/* webpackChunkName: "emailResetPassword" */ './views/EmailResetPasswordView.vue')
    },
    {
      path: '/reset-password/user/:token',
      name: 'resetPasswordUser',
      meta: { type: ResetPasswordType.USER_RESET },
      component: () => import(/* webpackChunkName: "resetPassword" */ './views/ResetPasswordView.vue')
    },
    {
      path: '/reset-password/next-login/:token',
      name: 'resetPasswordNextLogin',
      meta: { type: ResetPasswordType.NEXT_LOGIN },
      component: () => import(/* webpackChunkName: "resetPassword" */ './views/ResetPasswordView.vue')
    },
    {
      path: '/reset-password/expiration/:token',
      name: 'resetPasswordExpiration',
      meta: { type: ResetPasswordType.EXPIRATION },
      component: () => import(/* webpackChunkName: "resetPassword" */ './views/ResetPasswordView.vue')
    },
    { path: '*', redirect: '/dashboard/home' },
    // Dashboard del home
    {
      path: '/dashboard',
      children: [
        {
          path: '',
          name: 'dashboard',
          redirect: '/dashboard/home'
        },
        {
          path: 'home',
          name: 'dashboardHome',
          meta: { tab: 'tab-dashboard' },
          component: () => import('./components/dashboard/home/DashboardHomeComponent.vue')
        },
        {
          path: 'position',
          name: 'dashboardPosition',
          meta: { tab: 'tab-position', keys: { and: ['dashboard.positions'] }, title: 'dashboardReports.position' },
          component: () => import('./components/position/PositionReport.vue')
        },
        {
          path: 'map',
          name: 'dashboardMap',
          meta: { tab: 'tab-map', keys: { and: ['dashboard.map'] }, title: 'dashboardReports.map' },
          component: () => import('./components/map-report/MapReport.vue')
        },
        {
          path: 'running-hours',
          meta: { tab: 'tab-running-hours', keys: { and: ['dashboard.running.hours'] }, title: 'dashboardReports.runningHours' },
          name: 'dashboardRunningHours',
          component: () => import('./components/running-hours-report/RunningHoursReport.vue')
        },
        {
          path: 'real-speed',
          meta: { tab: 'tab-real-speed', keys: { and: ['dashboard.real.speed'] }, title: 'dashboardReports.speed' },
          name: 'dashboardRealSpeed',
          component: () => import('./components/real-speed-report/RealSpeedReport.vue')
        },
        {
          path: 'harvester',
          meta: { tab: 'tab-harvester', keys: { and: ['dashboard.harverst'] }, title: 'harvesters.reports.dashboard' },
          name: 'dashboardHarvester',
          component: () => import('./components/harvesters/dashboard/dashboard.vue')
        },
        {
          path: 'truck',
          meta: { tab: 'tab-truck', keys: { and: ['dashboard.truck'] }, title: 'trucks.reports.currentStatus.title' },
          name: 'dashboardTruck',
          component: () => import('./components/trucks/reports/current-status-report/CurrentStatusReport.vue')
        },
        {
          path: 'current-status',
          meta: { tab: 'tab-current-status', keys: { and: ['dashboard.real.state'] }, title: 'machines.reports.currentStatus' },
          name: 'dashboardCurrentStatus',
          component: () => import('./components/machine/current-status-report/CurrentStatusReport.vue'),
          props: { model: {} }
        },
        {
          path: 'machine-summary',
          meta: { tab: 'tab-machine-summary', keys: { and: ['dashboard.machine.summary'] }, title: 'machines.reports.summary' },
          name: 'dashboardMachineSummary',
          component: () => import('./components/machine/summary-report/MachineSummaryReport.vue'),
          props: { model: {} }
        },
        {
          path: 'on-board-monitor',
          meta: { tab: 'tab-service-order', keys: { and: ['dashboard.service.order'] }, title: 'MDB.title' },
          name: 'dashboardServiceOrder',
          component: () => import('./components/on-board-monitor/MDBReport.vue'),
          props: { model: {} }
        },
        {
          path: 'alert-dashboard',
          meta: { tab: 'tab-alert-dashboard', keys: { and: ['dashboard.alertDashboard'] }, title: 'alerts.title' },
          name: 'dashboardAlertDashboard',
          component: () => import('./components/alert-report/AlertReportComponent.vue'),
          props: { model: {} }
        },
        {
          path: 'engine-state-dashboard',
          meta: { tab: 'tab-engine-state-dashboard', keys: { and: ['dashboard.engineStateDashboard'] }, title: 'dashboardReports.engineStatus' },
          name: 'dashboardEngineStateDashboard',
          component: () => import('./components/engine-state-report/EngineStateReportComponent.vue'),
          props: { model: {} }
        },
        {
          path: 'objective-tendency',
          meta: { tab: 'tab-objective-tendency', keys: { and: ['dashboard.objectiveTendency'] }, title: 'dashboardReports.objectiveTendency' },
          name: 'dashboardObjectiveTendencyDashboard',
          component: () => import('./components/objective-tendency/ObjectiveTendencyComponent.vue'),
          props: { model: {} }
        },
        {
          path: 'efficiency',
          meta: { tab: 'tab-efficiency', keys: { and: ['dashboard.efficiency'] }, title: 'dashboardReports.efficiency' },
          name: 'dashboardEffiencyDashboard',
          component: () => import('./components/efficiency/EfficiencyComponent.vue'),
          props: { model: {} }
        }
      ],
      component: () => import('./views/DashboardView.vue')
    },
    // Ingeniería de campo
    {
      path: '/field-engineering',
      component: () => import('./views/FieldEngineeringView.vue'),
      children: [
        {
          path: 'dashboard',
          component: () => import('./components/dashboard/field-engineering/FieldEngineeringDashboard.vue'),
          meta: { keys: { and: ['field.engineering.dashboard'] }, title: 'dashboardReports.fieldEngineering' }
        },
        {
          path: 'summary-report',
          component: () => import('./components/machine/summary-report/MachineSummaryReport.vue'),
          props: { model: { sector: 'FIELD_ENGINEERING' } },
          meta: { keys: { and: ['field.engineering.summary.report'] }, title: 'machines.reports.summary' }
        },
        {
          path: 'current-status',
          component: () => import('./components/machine/current-status-report/CurrentStatusReport.vue'),
          props: { model: { sector: 'FIELD_ENGINEERING' } },
          meta: { keys: { and: ['field.engineering.current.state'] }, title: 'machines.reports.currentStatus' }
        }
      ]
    },
    {
      path: '/generic',
      component: () => import('./views/FieldEngineeringView.vue'),
      children: [
        {
          path: 'summary-report',
          component: () => import('./components/machine/summary-report/MachineSummaryReport.vue'),
          props: { model: { sector: null } },
          meta: { keys: { and: ['generic.summary.report'] }, title: 'machines.reports.summary' }
        },
        {
          path: 'current-status',
          component: () => import('./components/machine/current-status-report/CurrentStatusReport.vue'),
          props: { model: { sector: null }, title: 'machines.reports.currentStatus' },
          meta: { keys: { and: ['generic.current.state'] } }
        }
      ]
    },
    // Finca
    {
      path: '/farm',
      component: () => import('./views/FieldEngineeringView.vue'), // TODO: Cambiar estos componentes por uno sólo genérico para no duplicar sin sentido
      children: [
        {
          path: 'dashboard',
          component: () => import('./components/dashboard/farm/FarmDashboard.vue'),
          meta: { keys: { and: ['farm.dashboard'] }, title: 'dashboardReports.farm' }
        },
        {
          path: 'summary-report',
          component: () => import('./components/machine/summary-report/MachineSummaryReport.vue'),
          props: { model: { sector: 'FARM' } },
          meta: { keys: { and: ['farm.summary.report'] }, title: 'machines.reports.summary' }
        },
        {
          path: 'current-status',
          component: () => import('./components/machine/current-status-report/CurrentStatusReport.vue'),
          props: { model: { sector: 'FARM' } },
          meta: { keys: { and: ['farm.current.state'] }, title: 'machines.reports.currentStatus' }
        }
      ]
    },
    // Cosecha
    {
      path: '/harvest',
      component: () => import('./views/HarvestView.vue'),
      children: [
        {
          path: 'dashboard',
          component: () => import('./components/dashboard/harvest/HarvestDashboard.vue'),
          meta: { keys: { and: ['harvest.dashboard'] }, title: 'harvesters.reports.dashboard' }
        },
        {
          path: 'summary-report',
          component: () => import('./components/machine/summary-report/MachineSummaryReport.vue'),
          props: { model: { sector: 'HARVEST' } },
          meta: { keys: { and: ['harvest.summary.report'] }, title: 'harvesters.reports.harvestersSummary.title' }
        },
        {
          path: 'current-status',
          component: () => import('./components/machine/current-status-report/CurrentStatusReport.vue'),
          props: { model: { sector: 'HARVEST' } },
          meta: { keys: { and: ['harvest.current.state'] }, title: 'harvesters.reports.currentStatus.title' }
        }
      ]
    },
    {
      path: '/harvesters',
      component: () => import('./views/HarvestersView.vue'),
      children: [
        {
          path: '',
          name: 'harvesterDashboard',
          meta: { text: i18n.t('harvesters.title'), keys: { and: [''] }, title: 'harvesters.reports.dashboard' },
          component: () => import('./components/harvesters/dashboard/dashboard.vue')
        },
        {
          path: 'current-status-report',
          component: () => import('./views/HarvesterCurrentStatusReportView.vue'),
          meta: { keys: { and: [''] } },
          children: [
            {
              path: '',
              name: 'currentStatusReport',
              component: () => import('./components/harvesters/reports/current-status-report/CurrentStatusReport.vue'),
              meta: { keys: { and: [''] } }
            },
            {
              path: 'detail',
              meta: { keys: { and: [''] } },
              name: 'currentStatusDetailReport',
              component: () => import('./components/harvesters/reports/current-status-report/current-status-detail-report/CurrentStatusDetailReport.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/trucks',
      meta: { text: i18n.t('trucks.title'), keys: { and: [''] } },
      component: () => import('./views/TrucksView.vue'),
      children: [
        {
          path: 'dashboard',
          component: () => import('./components/dashboard/transport/TransportDashboard.vue'),
          meta: { keys: { and: ['truck.dashboard'] }, title: 'trucks.reports.dashboard' }
        },
        {
          path: 'summary-report',
          name: 'trucksSummaryReport',
          meta: { tab: 'tab-summary', keys: { and: ['truck.summary.resport'] }, title: 'trucks.reports.summary.title' },
          component: () => import('./components/trucks/reports/summary-report/TruckSummaryReport.vue')
        },
        {
          path: 'historical-report',
          name: 'trucksHistoricalReport',
          meta: { keys: { and: ['truck.historical.report'] }, title: 'trucks.reports.historical.title' },
          component: () => import('./components/trucks/reports/historical-report/HistoricalReport.vue')
        },
        {
          path: 'current-status-report',
          meta: { keys: { and: ['truck.current.state'] }, title: 'trucks.reports.currentStatus.title' },
          component: () => import('./components/trucks/reports/current-status-report/CurrentStatusReport.vue')
        },
        {
          path: 'rrhh',
          name: 'rrhhMap',
          meta: { tab: 'tab-map', keys: { and: ['dashboard.map'] }, title: 'trucks.reports.rrhh.title' },
          component: () => import('./components/map-report-trailers/MapReport.vue')
        }
      ]
    },
    {
      path: '/harvest-fronts',
      meta: { keys: { and: [''] } },
      component: () => import(/* webpackChunkName: "harvest-fronts" */ './views/HarvestFrontsView.vue'),
      children: [
        {
          path: '',
          nam: 'harvestFrontDashboard',
          meta: { keys: { and: [''] } },
          component: () => import('./components/dashboard/mock-chartset/FirstChartSetComponent.vue')
        },
        {
          path: 'location-report',
          name: 'harvestFrontLocationReport',
          meta: { tab: 'tab-map', keys: { and: ['harvest.front.location.resport'] }, title: 'harvestFronts.reports.hfLocation.title' },
          component: () => import('./components/harvest-front-location/HarvestFrontLocationReport.vue')
        }
      ]
    },
    {
      path: '/trailers',
      name: 'trailers',
      meta: { text: i18n.t('trailers.title'), keys: { and: [''] } },
      component: () => import(/* webpackChunkName: "trailers" */ './views/TrailersView.vue')
    },
    // Se envían como query params el número de página, el tamaño de página y el id de la entidad que se está actualizando (opcional)
    // Si no se setea el tamaño y número de página, trae todos los resultados
    // Ejemplo: /vinculations?pageSize=50&pageNumber=1?id=kjadhf7823e63gvf
    {
      path: '/vinculations',
      component: () => import('./views/VinculationView.vue'),
      meta: { keys: { and: ['configurations'] } },
      children: [
        {
          path: '',
          name: 'vinculationsABM',
          meta: { keys: { and: ['configurations.vinculations'] }, title: 'navbar.categories.settings.abmvinculaciones.title' },
          component: () => import('./components/vinculation/VinculationABMComponent.vue')
        },
        {
          path: 'history',
          name: 'vinculationsHistory',
          meta: { keys: { and: ['configurations.vinculations.history'] }, title: 'navbar.categories.settings.historicovinculaciones.title' },
          component: () => import('./components/vinculation/history/VinculationHistoryComponent.vue')
        }
      ]
    },
    {
      path: '/HistoricTruckDispatcher',
      component: () => import('./views/HistoricTruckDispatcherView.vue'),
      children: [
        {
          path: '',
          name: 'HistoricTruckDispatcher',
          meta: { title: 'historicTruckDispatcher.title' },
          component: () => import('./components/smart-truck-dispatcher/historic-truck-dispatcher/HistoricTruckDispatcherComponent.vue')
        },

      ]
    },
    {
      path: '/HistoricTruckDispatcherMap',
      name: 'HistoricTruckDispatcherMap',
      component: () => import('./components/harvestFrontHistory/HarvestFrontMapHistoryComponent.vue'),
    },
    {
      path: '/SmartTruckDispatcher',
      component: () => import('./views/SmartTruckDispatcherView.vue'),
      children: [
        {
          path: '',
          name: 'SmartTruckDispatcher',
          meta: { keys: { and: ['dashboard.smartTruckDispatcher'] }, title: 'smartTruckDispatcher.title' },
          component: () => import('./components/smart-truck-dispatcher/SmartTruckDispatcherComponent.vue')
        },

      ]
    },
    {
      path: '/truckDispatch',
      component: () => import('./views/HarvestFrontsView.vue'),
      children: [
        {
          path: '',
          name: 'truckDispatch',
          meta: { title: 'truckDispatch.title' },
          component: () => import('./components/smart-truck-dispatcher/TruckDispatch/TruckDispatchComponent.vue')
        }
      ]
    },
    {
      path: '/serviceOrder',
      component: () => import('./views/ServiceOrderView.vue'),
      children: [
        {
          path: '',
          name: 'serviceOrderABM',
          meta: { title: 'navbar.categories.settings.abmserviceorder.title' },
          component: () => import('./components/service-order/ServiceOrderABMComponent.vue')
        }
      ]
    },
    {
      path: '/reminder',
      component: () => import('./views/ReminderView.vue'),
      children: [
        {
          path: '',
          name: 'reminderABM',
          meta: { title: 'navbar.categories.settings.abmreminder.title' },
          component: () => import('./components/reminder/abm/ReminderABMComponent.vue')
        },
        {
          path: 'type',
          name: 'reminderTypeABM',
          meta: { title: 'navbar.categories.settings.abmreminderType.title' },
          component: () => import('./components/reminder/type/ReminderTypeABMComponent.vue')
        },
        {
          path: 'plan',
          name: 'planABM',
          meta: { title: 'navbar.categories.settings.abmreminderPlan.title' },
          component: () => import('./components/reminder/plan/PlanABMComponent.vue')
        },
        {
          path: 'confirmation',
          name: 'reminderConfirmation',
          meta: { title: 'navbar.categories.settings.reminderConfirmation.title' },
          component: () => import('./components/reminder/confirmation/ReminderConfirmationComponent.vue')
        },
        {
          path: 'history',
          name: 'reminderDoneReport',
          meta: { title: 'navbar.categories.settings.reminderHistory.title' },
          component: () => import('./components/reminder/done-report/DoneRemindersReport.vue')
        },
        {
          path: 'summary',
          name: 'reminderToDoReport',
          meta: { title: 'navbar.categories.settings.reminderSummary.title' },
          component: () => import('./components/reminder/to-do-report/RemindersToDoReport.vue')
        }
      ]
    },
    {
      path: '/work-area',
      component: () => import(/* webpackChunkName: "trucks" */ './views/WorkedAreaView.vue'),
      // meta: { tab: 'tab-dashboard', keys: { and: ['work.area.dashboard'] } },
      children: [
        {
          path: 'dashboard',
          component: () => import('./components/drive-units/dashboard/dashboard.vue'),
          meta: { keys: { and: ['work.area.dashboard'] }, title: 'driveUnits.reports.harvestedAreaDashboard.title' }
        },
        {
          path: 'summary-report',
          name: 'harvestedAreaSummaryReport',
          meta: { tab: 'tab-summary', keys: { and: ['work.area.summary.report'] }, title: 'driveUnits.reports.harvestedArea.title' },
          component: () => import('./components/drive-units/reports/harvested-area/HarvestedAreaReport.vue')
        }
      ]
    },
    {
      path: '/position',
      name: 'dashboardPosition',
      meta: { tab: 'tab-position', keys: { and: ['dashboard.positions'] }, title: 'dashboardReports.position' },
      component: () => import('./components/position/PositionReport.vue')
    },
    {
      path: '/map',
      name: 'dashboardMap',
      meta: { tab: 'tab-map', keys: { and: ['dashboard.map'] }, title: 'dashboardReports.map' },
      component: () => import('./components/map-report/MapReport.vue')
    },
    {
      path: '/running-hours',
      meta: { tab: 'tab-running-hours', keys: { and: ['dashboard.running.hours'] }, title: 'dashboardReports.runningHours' },
      name: 'dashboardRunningHours',
      component: () => import('./components/running-hours-report/RunningHoursReport.vue')
    },
    {
      path: '/real-speed',
      meta: { tab: 'tab-real-speed', keys: { and: ['dashboard.real.speed'] }, title: 'dashboardReports.speed' },
      name: 'dashboardRealSpeed',
      component: () => import('./components/real-speed-report/RealSpeedReport.vue')
    },
    {
      path: '/harvester',
      meta: { tab: 'tab-harvester', keys: { and: ['dashboard.harverst'] }, title: 'dashboardReports.harvest' },
      name: 'dashboardHarvester',
      component: () => import('./components/harvesters/dashboard/dashboard.vue')
    },
    {
      path: '/truck',
      meta: { tab: 'tab-truck', keys: { and: ['dashboard.truck'] }, title: 'trucks.reports.currentStatus.title' },
      name: 'dashboardTruck',
      component: () => import('./components/trucks/reports/current-status-report/CurrentStatusReport.vue')
    },
    {
      path: '/current-status',
      meta: { tab: 'tab-current-status', keys: { and: ['dashboard.real.state'] }, title: 'machines.reports.currentStatus' },
      name: 'dashboardCurrentStatus',
      component: () => import('./components/machine/current-status-report/CurrentStatusReport.vue'),
      props: { model: {} }
    },
    {
      path: '/on-board-monitor',
      meta: { tab: 'tab-service-order', keys: { and: ['dashboard.service.order'] }, title: 'MDB.title' },
      name: 'dashboardServiceOrder',
      component: () => import('./components/on-board-monitor/MDBReport.vue'),
      props: { model: {} }
    },
    {
      path: '/tracking-map',
      name: 'trackingMap',
      component: () => import('./components/commons/map/component/TrackingMap.vue'),
    },
    {
      path: '/traceability',
      name: 'traceability',
      component: () => import('./views/TraceabilityView.vue'),
      children: [
        {
          path: 'cdc-report',
          component: () => import('./components/traceability/cdc-report/CdcReport.vue'),
          meta: { keys: { and: ['traceability.cdc.report'] }, title: 'navbar.categories.traceability.submenu.CDCReport' },
          props: { model: {} }
        },
        {
          path: 'cdp-report',
          component: () => import('./components/cdp-report/CdpReport.vue'),
          props: { model: { sector: 'FARM' } },
          meta: { keys: { and: ['traceability.cdp.report'] }, title: 'machines.reports.cdp' }
        }
      ]
    },
    {
      path: '/mapMonitor',
      name: 'mapMonitor',
      component: () => import('./components/map/MapBaseComponent.vue'),
    },
    {
      path: '/mapAnalitic',
      name: 'mapAnalitic',
      meta: { keys: { and: ['configurations.mapHistory'] }},
      component: () => import('./components/mapHistory/MapHistoryComponent.vue'),
    },
    {
      path: '/harvestFrontsMap',
      name: 'harvestFrontsMap',
      component: () => import('./components/HarvestFrontMap/HarvestFrontMapComponent.vue'),
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Configurar lenguaje
  if (localStorage.getItem('locale')) {
    i18n.locale = localStorage.getItem('locale')
  } else {
    i18n.locale = navigator.language
  }

  // Ocultar header y footer en el login
  if (to && (to.name === 'login' || to.name === 'resetPasswordUser' || to.name === 'resetPasswordExpiration' || to.name === 'resetPasswordNextLogin' || to.name === 'emailResetPassword')) {
    store.commit('app/hideHeader')
    store.commit('app/hideFooter')
  } else {
    store.commit('app/showHeader')
    store.commit('app/showFooter')
    if (!localStorage.getItem('token')) {
      store.commit('app/hideHeader')
      store.commit('app/hideFooter')
      next({
        path: '/login'
      })
      return
    } else {
      store.commit('app/showHeader')
      store.commit('app/showFooter')
      if (to.name === "dashboardObjectiveTendencyDashboard" && !JSON.parse(localStorage.getItem('enterpriseTimeSpan')).shift) {
        next({
          path: '/dashboard'
        })
        return
      }
      if (!JSON.parse(localStorage.getItem('admin'))) {
        if (to.meta && to.meta.keys) {
          const result = privilegesService.validatePrivileges(to.meta.keys)
          if (!result) {
            next({
              path: '/dashboard'
            })
            return
          }
        }
      }
    }
  }
  next()
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? i18n.t(to.meta.title) : defaultTitle
  })
})

/*
  Error de missing chunks y relacionados

  TO DO: verificar tipo de error (no se encontró documentación)
*/
router.onError(error => {
  /*
  if (/loading chunk \d* failed./i.test(error.message)) {
  }
  */
  console.warn('Error de chunks, recargue la página: ', error)
  store.dispatch('snackbar/showSnackbar', {
    visible: true,
    text: i18n.t('error.missingChunks'),
    timeout: 9500,
    style: SnackbarStyle.WARNING
  })
})

export default router
