import { arraysEqual } from '@/tools/functions'
import deepcopy from 'deepcopy'
/*
notification = {
  _id: '2k21jh421kj4218'
  userId: 'lgkju6o589ukjh',
  title: 'TITULO',
  text: 'TEXTO',
  type: 'REPORT',
  url: '/harvesters/current-status-report',
  key: 'djhdgaskjhwg2r3i7ur',
  show: false,
  delete: false,
  date: new Date()
*/
export const state = () => ({
  dark: localStorage.getItem('dark') ? localStorage.getItem('dark') === 'true' : false,
  displayHeader: false,
  displayFooter: false,
  displayBreadcrumb: false,
  navBarButtons: [],
  navBarSettings: [
    {
      title: 'navbar.categories.settings.abmvinculaciones.title',
      route: '/vinculations',
      icon: 'list_alt',
      keys: { and: ['configurations.vinculations'] }
    },
    {
      title: 'navbar.categories.settings.historicovinculaciones.title',
      route: '/vinculations/history',
      icon: 'list_alt',
      keys: { and: ['configurations.vinculations.history'] }
    },
    {
      title: 'navbar.categories.settings.abmserviceorder.title',
      route: '/serviceOrder',
      icon: 'list_alt',
      keys: { and: ['configurations.service.order'] }
    },
    {
      title: 'truckDispatch.title',
      route: '/truckDispatch',
      icon: 'list_alt',
      keys: { and: ['configurations.truck.dispatch'] }
    },
    {
      title: 'navbar.categories.settings.abmreminder.title',
      route: '/reminder',
      icon: 'list_alt',
      keys: { and: ['configurations.reminder'] }
    },
    {
      title: 'navbar.categories.settings.abmreminderType.title',
      route: '/reminder/type',
      icon: 'list_alt',
      keys: { and: ['configurations.reminder.type'] }
    },
    {
      title: 'navbar.categories.settings.abmreminderPlan.title',
      route: '/reminder/plan',
      icon: 'list_alt',
      keys: { and: ['configurations.reminder.plan'] }
    },
    {
      title: 'navbar.categories.settings.reminderConfirmation.title',
      route: '/reminder/confirmation',
      icon: 'list_alt',
      keys: { and: ['configurations.reminder.confirmation'] }
    },
    {
      title: 'navbar.categories.settings.reminderHistory.title',
      route: '/reminder/history',
      icon: 'list_alt',
      keys: { and: ['configurations.reminder.history'] }
    },
    {
      title: 'navbar.categories.settings.reminderSummary.title',
      route: '/reminder/summary',
      icon: 'list_alt',
      keys: { and: ['configurations.reminder.summary'] }
    }
  ],
  breadcrumbRoute: [],
  entitySelectorItems: [],
  selectedItem: undefined,
  entitySelectorConfiguration: { value: '', text: '', name: '' },
  notifications: [],
  badgeCount: 0,
  notificationSound: false,
  newNotification: undefined,
  notificationsTotalCount: 0,
  // patrones para las líneas del mapa
  mapLinePatterns: localStorage.getItem('mapLinePatterns') != null ? JSON.parse(localStorage.getItem('mapLinePatterns')) : null,
  // boolean para habilitar el dibujado de las líneas del mapa con patrones
  mapLinePatternsEnabled: localStorage.getItem('mapLinePatternsEnabled') != null ? localStorage.getItem('mapLinePatternsEnabled') : false
})

const getters = {
  getDark: (state) => {
    return state.dark
  },
  getDisplayHeader: (state) => {
    return state.displayHeader
  },
  getDisplayFooter: (state) => {
    return state.displayFooter
  },
  getDisplayBreadcrumb: (state) => {
    return state.displayBreadcrumb
  },
  getNavBarButtons: (state) => {
    return state.navBarButtons
  },
  getBreadcrumbRoute: (state) => {
    return state.breadcrumbRoute
  },
  getEntitySelectorItems: (state) => {
    return state.entitySelectorItems
  },
  getSelectedItem: (state) => {
    return state.selectedItem
  },
  getEntitySelectorConfiguration: (state) => {
    return state.entitySelectorConfiguration
  },
  getNotifications: (state) => {
    return state.notifications
  },
  getNotificationsCount: (state) => {
    return state.notifications ? state.notifications.length : 0
  },
  getNotificationsTotalCount: (state) => {
    return state.notificationsTotalCount
  },
  getBadgeCount: (state) => {
    return state.badgeCount
  },
  getNotificationSound: (state) => {
    return state.notificationSound
  },
  getNewNotification: (state) => {
    return state.newNotification
  },
  isDisplayShowMoreNotifications: (state) => {
    return state.notifications.length !== state.notificationsTotalCount
  },
  // patrones para las lineas del mapa
  getMapLinePatterns: (state) => {
    return state.mapLinePatterns
  },
  // boolean que habilita dibujar las líneas con patrones
  getMapLinePatternsEnabled: (state) => {
    return state.mapLinePatternsEnabled
  }
}

const actions = {
  setEntitySelectorItems ({ commit }, payload) {
    commit('setEntitySelectorItems', payload)
  },
  setNavBarButtons ({ commit }, payload) {
    commit('setNavBarButtons', payload)
  },
  switchDark ({ commit }) {
    commit('switchDark')
  },
  // patrones para las lineas del mapa
  setMapLinePatterns: ({ commit }, payload) => {
    if (payload != null) {
      commit('commitMapLinePatterns', payload)
      localStorage.setItem('mapLinePatterns', JSON.stringify(payload))
    } else {
      commit('commitMapLinePatterns', null)
      localStorage.removeItem('mapLinePatterns')
    }
  },
  // boolean que habilita dibujar las líneas con patrones
  setMapLinePatternsEnabled: ({ commit }, payload) => {
    if (payload != null) {
      commit('commitMapLinePatternsEnabled', payload)
      localStorage.setItem('mapLinePatternsEnabled', payload)
    } else {
      commit('commitMapLinePatternsEnabled', false)
      localStorage.setItem('mapLinePatternsEnabled', false)
    }
  }
}

const mutations = {
  showHeader (state) {
    state.displayHeader = true
  },
  showFooter (state) {
    state.displayFooter = true
  },
  hideHeader (state) {
    state.displayHeader = false
  },
  hideFooter (state) {
    state.displayFooter = false
  },
  setNavBarButtons (state, payload) {
    state.navBarButtons = payload
  },
  setBreadcrumbRoute (state, payload) {
    if (!arraysEqual(state.breadcrumbRoute, payload)) {
      state.breadcrumbRoute = payload
    }
    state.breadcrumbRoute = payload
  },
  pushBreadcrumbRouteItem (state, payload) {
    state.breadcrumbRoute.push(payload)
  },
  popBreadcrumbRouteItem (state) {
    state.breadcrumbRoute.pop()
  },
  showBreadcrumb (state) {
    state.displayBreadcrumb = true
  },
  hideBreadcrumb (state) {
    state.displayBreadcrumb = false
  },
  setEntitySelectorItems (state, payload) {
    state.entitySelectorItems = payload
  },
  setSelectedItem (state, payload) {
    state.selectedItem = payload
  },
  setEntityConfiguration (state, payload) {
    state.entitySelectorConfiguration = payload
  },
  resetEntitySelector (state) {
    state.entitySelectorItems = []
    state.entitySelectorConfiguration = { value: '', text: '', name: '' }
    state.selectedItem = undefined
  },
  setNotifications (state, payload) {
    state.notifications = payload
  },
  addNewNotification (state, payload) {
    payload.show = payload.show === 'true'
    payload.delete = payload.delete === 'true'
    state.newNotification = payload
    const newNotificationArray = [payload]
    const notificationsCopy = deepcopy(state.notifications)
    Array.prototype.push.apply(newNotificationArray, notificationsCopy)
    state.notifications = newNotificationArray
    state.badgeCount++
  },
  addNotifications (state, payload) {
    const notificationsCopy = deepcopy(state.notifications)
    Array.prototype.push.apply(notificationsCopy, payload)
    state.notifications = notificationsCopy
  },
  readNotification (state, payload) {
    const notification = state.notifications.find(n => n._id === payload._id)
    notification.show = payload.value
  },
  readAllNotifications (state, payload) {
    state.notifications.forEach(notification => {
      notification.show = payload.value
    })
  },
  deleteNotification (state, payload) {
    const notification = state.notifications.find(n => n._id === payload._id)
    notification.delete = true
  },
  deleteAllNotifications (state) {
    state.notifications.forEach(notification => {
      notification.delete = true
    })
  },
  setBadgeCount (state, payload) {
    state.badgeCount = payload.badgeCount
  },
  incrementBadgeCount (state) {
    state.badgeCount++
  },
  reduceBadgeCount (state) {
    state.badgeCount--
  },
  switchNotificationSound (state) {
    state.notificationSound = !state.notificationSound
  },
  setNewNotification (state, payload) {
    state.newNotification = payload
  },
  resetNewNotification () {
    state.newNotification = undefined
  },
  setNotificationsTotalCount (state, count) {
    state.notificationsTotalCount = count
  },
  resetAppData (state) {
    state.breadcrumbRoute = []
    state.entitySelectorItems = []
    state.selectedItem = undefined
    state.entitySelectorConfiguration = { value: '', text: '', name: '' }
    state.notifications = []
    state.badgeCount = 0
    state.notificationSound = true
    state.newNotification = undefined
    state.notificationsTotalCount = 0
    state.navBarButtons = []
  },
  switchDark (state) {
    state.dark = !state.dark
    localStorage.setItem('dark', state.dark)
  },
  commitMapLinePatterns (state, mapLinePatterns) {
    state.mapLinePatterns = mapLinePatterns
  },
  commitMapLinePatternsEnabled (state, mapLinePatternsEnabled) {
    state.mapLinePatternsEnabled = mapLinePatternsEnabled
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
